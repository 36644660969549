// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fluid-form {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 200px 1fr 10px 200px 1fr 10px;
    grid-row-gap: 20px;
    align-items: center;
    margin-bottom: 30px;
}

.fluid-form span:nth-child(3n + 1) {
    text-align: right;
}

.fluid-form span:nth-child(3n) {
    -webkit-user-select: none;
            user-select: none;
}

.fluid-form span:nth-child(3n):hover {
    cursor: pointer;
    font-weight: bold;
    font-size: 120%;
}
`, "",{"version":3,"sources":["webpack://./src/app/document-query/document-query.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qBAAqB;IACrB,oDAAoD;IACpD,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".fluid-form {\n    display: grid;\n    grid-column-gap: 20px;\n    grid-template-columns: 200px 1fr 10px 200px 1fr 10px;\n    grid-row-gap: 20px;\n    align-items: center;\n    margin-bottom: 30px;\n}\n\n.fluid-form span:nth-child(3n + 1) {\n    text-align: right;\n}\n\n.fluid-form span:nth-child(3n) {\n    user-select: none;\n}\n\n.fluid-form span:nth-child(3n):hover {\n    cursor: pointer;\n    font-weight: bold;\n    font-size: 120%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
