// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section {
  display: inline-block;
  vertical-align: bottom;
}

svg {
  height: 120px;
  width: 100%;
}

.noteText, .syllableText {
  white-space: nowrap;
  text-align: center;
  border: 1px dashed white;
  margin: 5px;
  font-family: "Times New Roman", Times, serif;
  font-size: 16px;
  visibility: hidden;
  line-height: 25px;
  height: 25px;
}

svg .note {
  cursor: pointer;
}

.read-only .noteText {
  display: none !important;
}
.read-only .syllableText {
  border: none !important;
}
.read-only svg {
  height: 90px;
}`, "",{"version":3,"sources":["webpack://./src/app/box/box.component.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,sBAAA;AACJ;;AAEA;EACI,aAAA;EACA,WAAA;AACJ;;AAEA;EACI,mBAAA;EACA,kBAAA;EACA,wBAAA;EACA,WAAA;EACA,4CAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,YAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAGI;EAAY,wBAAA;AAChB;AAAI;EAAgB,uBAAA;AAGpB;AAFI;EAAM,YAAA;AAKV","sourcesContent":[".section {\n    display: inline-block;\n    vertical-align: bottom;\n}\n\nsvg {\n    height: 120px;\n    width: 100%;\n}\n\n.noteText, .syllableText {\n    white-space: nowrap;\n    text-align: center;\n    border: 1px dashed white;\n    margin: 5px;\n    font-family: \"Times New Roman\", Times, serif;\n    font-size: 16px;\n    visibility: hidden;\n    line-height: 25px;\n    height: 25px;\n}\n\nsvg .note {\n    cursor: pointer;\n}\n\n.read-only {\n    .noteText { display: none !important; }\n    .syllableText { border: none !important; }\n    svg { height: 90px; }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
