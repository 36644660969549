// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.062745098);
  border: 1px solid rgba(0, 0, 0, 0.1254901961);
  display: grid;
  grid-template-columns: repeat(4, auto);
  justify-items: center;
  align-items: center;
  grid-gap: 1rem;
  overflow-x: auto;
}

.option {
  text-align: center;
  background-color: #0d5d80;
  color: white;
  padding: 0.5rem;
  border-radius: 0.3rem;
  cursor: pointer;
}
.option:hover {
  color: gold;
}`, "",{"version":3,"sources":["webpack://./src/app/complex-comment/comment-tree/comment-tree-undecided/comment-tree-undecided.component.scss","webpack://./src/app/complex-comment/comment-tree/_common.scss"],"names":[],"mappings":"AAEA;ECDE,cAAA;EACA,aAAA;EACA,4CAAA;EACA,6CAAA;EDCA,aAAA;EACA,sCAAA;EACA,qBAAA;EACA,mBAAA;EACA,cAAA;EACA,gBAAA;AACF;;AAEA;EACE,kBAAA;EACA,yBAAA;EACA,YAAA;EACA,eAAA;EACA,qBAAA;EACA,eAAA;AACF;AACE;EACE,WAAA;AACJ","sourcesContent":["@use \"../common\";\n\n:host {\n  @include common.block;\n\n  display: grid;\n  grid-template-columns: repeat(4, auto);\n  justify-items: center;\n  align-items: center;\n  grid-gap: 1rem;\n  overflow-x: auto;\n}\n\n.option {\n  text-align: center;\n  background-color: #0d5d80;\n  color: white;\n  padding: 0.5rem;\n  border-radius: 0.3rem;\n  cursor: pointer;\n\n  &:hover {\n    color: gold;\n  }\n}\n","@mixin block {\n  display: block;\n  padding: 1rem;\n  background-color: #00000010;\n  border: 1px solid #00000020;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
