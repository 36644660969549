// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
}

.bracket {
  width: 1rem;
  border-width: 0.3rem;
  border-color: #000;
  border-right-style: solid;
  border-top-style: solid;
  border-bottom-style: solid;
}`, "",{"version":3,"sources":["webpack://./src/app/complex-comment/comment-tree/comment-tree-leaf/comment-tree-bracket/comment-tree-bracket.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,2BAAA;EACA,uBAAA;AACF;;AAEA;EACE,WAAA;EACA,oBAAA;EACA,kBAAA;EACA,yBAAA;EACA,uBAAA;EACA,0BAAA;AACF","sourcesContent":[":host {\n  display: grid;\n  grid-template-columns: auto;\n  justify-content: center;\n}\n\n.bracket {\n  width: 1rem;\n  border-width: 0.3rem;\n  border-color: #000;\n  border-right-style: solid;\n  border-top-style: solid;\n  border-bottom-style: solid;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
