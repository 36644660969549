// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bottom-padding {
  height: 100px;
}

.import-errors {
  margin-top: 20px;
  padding: 5px;
  border-radius: 5px;
  background: lightcoral;
}

ul {
  margin-bottom: 0px;
}

.disabled-input {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
}

@media print {
  body * {
    visibility: hidden;
    float: none;
  }

  .container {
    overflow: visible;
    visibility: visible;
    position: absolute;
    left: 0px;
    top: 0px;
  }

  .collapse {
    display: block;
  }

  .row {
    display: block;
  }
}

.row.internal-id-row .col {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.row.internal-id-row .col span:nth-child(1) {
  font-weight: bold;
}
.row.internal-id-row .col input {
  display: block;
  padding: 0.5em;
  width: 40em;
  border: 1px solid #89bdd3;
}
.row.internal-id-row .col > * {
  margin: 0.3em 0 0.3em 0.3em;
}

`, "",{"version":3,"sources":["webpack://./src/app/document/document.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,2BAA2B;EAC3B,yBAAyB;EACzB,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,sBAAsB;EACtB,4BAA4B;EAC5B,YAAY;AACd;;AAEA;EACE;IACE,kBAAkB;IAClB,WAAW;EACb;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,SAAS;IACT,QAAQ;EACV;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,cAAc;EAChB;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,cAAc;EACd,cAAc;EACd,WAAW;EACX,yBAAyB;AAC3B;AACA;EACE,2BAA2B;AAC7B","sourcesContent":[".bottom-padding {\n  height: 100px;\n}\n\n.import-errors {\n  margin-top: 20px;\n  padding: 5px;\n  border-radius: 5px;\n  background: lightcoral;\n}\n\nul {\n  margin-bottom: 0px;\n}\n\n.disabled-input {\n  display: block;\n  width: 100%;\n  height: calc(2.25rem + 2px);\n  padding: 0.375rem 0.75rem;\n  font-size: 1rem;\n  line-height: 1.5;\n  color: #495057;\n  background-color: #fff;\n  background-clip: padding-box;\n  border: none;\n}\n\n@media print {\n  body * {\n    visibility: hidden;\n    float: none;\n  }\n\n  .container {\n    overflow: visible;\n    visibility: visible;\n    position: absolute;\n    left: 0px;\n    top: 0px;\n  }\n\n  .collapse {\n    display: block;\n  }\n\n  .row {\n    display: block;\n  }\n}\n\n.row.internal-id-row .col {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n.row.internal-id-row .col span:nth-child(1) {\n  font-weight: bold;\n}\n.row.internal-id-row .col input {\n  display: block;\n  padding: 0.5em;\n  width: 40em;\n  border: 1px solid #89bdd3;\n}\n.row.internal-id-row .col > * {\n  margin: 0.3em 0 0.3em 0.3em;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
