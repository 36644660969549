// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `th {
    white-space: nowrap;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
}

th:hover {
    color: #89bdd3;
}

th:last-child {
    cursor: default;
}

.delete-icon {
    cursor: pointer;
}

.td-smart-row {
    cursor: pointer;
}

tbody tr:hover {
    background-color: #cfe6f0;
}`, "",{"version":3,"sources":["webpack://./src/app/smart-table/smart-table.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,eAAe;IACf,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["th {\n    white-space: nowrap;\n    cursor: pointer;\n    user-select: none;\n}\n\nth:hover {\n    color: #89bdd3;\n}\n\nth:last-child {\n    cursor: default;\n}\n\n.delete-icon {\n    cursor: pointer;\n}\n\n.td-smart-row {\n    cursor: pointer;\n}\n\ntbody tr:hover {\n    background-color: #cfe6f0;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
