// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `canvas {
    display: none;
}

svg {
    width: 0;
}

.navbar {
    background-color: #89bdd3 !important;
}

.monodi-tool-bar {
    background-color: #9ad3de;
    display: grid;
    grid-template-columns: repeat(auto-fit, 45px);
    justify-items: center;
    justify-content: center;
}

.monodi-tool-bar > img {
    cursor: pointer;
    width: 34px;
    height: 34px;
}

.monodi-tool-bar > img:hover {
    background-color: #7ac3ce;
}

.source-sigle {
    white-space: nowrap;
    font-size: 1.25rem;
}

@media print {

    .monodi-tool-bar {
        display: none;
    }

    app-route {
        overflow: hidden;
        height: unset;
    }
}
`, "",{"version":3,"sources":["webpack://./src/app/app.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,QAAQ;AACZ;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,6CAA6C;IAC7C,qBAAqB;IACrB,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;;IAEI;QACI,aAAa;IACjB;;IAEA;QACI,gBAAgB;QAChB,aAAa;IACjB;AACJ","sourcesContent":["canvas {\n    display: none;\n}\n\nsvg {\n    width: 0;\n}\n\n.navbar {\n    background-color: #89bdd3 !important;\n}\n\n.monodi-tool-bar {\n    background-color: #9ad3de;\n    display: grid;\n    grid-template-columns: repeat(auto-fit, 45px);\n    justify-items: center;\n    justify-content: center;\n}\n\n.monodi-tool-bar > img {\n    cursor: pointer;\n    width: 34px;\n    height: 34px;\n}\n\n.monodi-tool-bar > img:hover {\n    background-color: #7ac3ce;\n}\n\n.source-sigle {\n    white-space: nowrap;\n    font-size: 1.25rem;\n}\n\n@media print {\n\n    .monodi-tool-bar {\n        display: none;\n    }\n\n    app-route {\n        overflow: hidden;\n        height: unset;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
