// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 10px;
}
.main .name {
  font-weight: bold;
  font-size: 120%;
  text-align: center;
}
.main .warning {
  font-weight: bold;
  font-size: 100%;
  text-align: center;
}
.main .errors {
  padding: 5px;
  background-color: #d65c30;
}
.main .errors .message {
  font-familiy: monospace;
  white-space: pre;
}
.main .success {
  padding: 5px;
  background-color: lightgreen;
}

.row {
  background-color: #d8e6f0;
  margin-top: 20px;
  padding-bottom: 10px;
}

.row.danger {
  background-color: orange;
}`, "",{"version":3,"sources":["webpack://./src/app/zip-upload/zip-upload.component.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,0BAAA;EACA,kBAAA;AACD;AACC;EACC,iBAAA;EACA,eAAA;EACA,kBAAA;AACF;AAEC;EACC,iBAAA;EACA,eAAA;EACA,kBAAA;AAAF;AAGC;EACC,YAAA;EACA,yBAAA;AADF;AAGE;EACC,uBAAA;EACA,gBAAA;AADH;AAKC;EACC,YAAA;EACA,4BAAA;AAHF;;AAOA;EACC,yBAAA;EACA,gBAAA;EACA,oBAAA;AAJD;;AAQA;EACC,wBAAA;AALD","sourcesContent":[".main {\n\tdisplay: grid;\n\tgrid-template-columns: 1fr;\n\tgrid-row-gap: 10px;\n\n\t.name {\n\t\tfont-weight: bold;\n\t\tfont-size: 120%;\n\t\ttext-align: center;\n\t}\n\n\t.warning {\n\t\tfont-weight: bold;\n\t\tfont-size: 100%;\n\t\ttext-align: center;\n\t}\n\n\t.errors {\n\t\tpadding: 5px;\n\t\tbackground-color: #d65c30;\n\n\t\t.message {\n\t\t\tfont-familiy: monospace;\n\t\t\twhite-space: pre;\n\t\t}\n\t}\n\n\t.success {\n\t\tpadding: 5px;\n\t\tbackground-color: lightgreen;\n\t}\n}\n\n.row {\n\tbackground-color: #d8e6f0;\n\tmargin-top: 20px;\n\tpadding-bottom: 10px;\n}\n\n\n.row.danger {\n\tbackground-color: orange;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
