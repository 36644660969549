// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: absolute;
  transform: translate(-1rem, -1rem);
}
:host:focus-within {
  background-color: white;
  border: 1px solid #ccc;
}
:host:focus-within svg .center {
  display: none;
}
:host:focus-within .action {
  display: block;
}

svg {
  display: block;
}

.action {
  display: none;
  margin: 0.5rem;
  cursor: pointer;
  text-decoration: underline;
  -webkit-user-select: none;
          user-select: none;
}

.action.active {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/complex-comment/comment-tree/comment-tree-action-dot/comment-tree-action-dot.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,kCAAA;AACF;AACE;EACE,uBAAA;EACA,sBAAA;AACJ;AAAI;EACE,aAAA;AAEN;AAAI;EACE,cAAA;AAEN;;AAGA;EACE,cAAA;AAAF;;AAGA;EACE,aAAA;EACA,cAAA;EACA,eAAA;EACA,0BAAA;EACA,yBAAA;UAAA,iBAAA;AAAF;;AAGA;EACE,iBAAA;AAAF","sourcesContent":[":host {\n  position: absolute;\n  transform: translate(-1rem, -1rem);\n\n  &:focus-within {\n    background-color: white;\n    border: 1px solid #ccc;\n    svg .center {\n      display: none;\n    }\n    .action {\n      display: block;\n    }\n  }\n}\n\nsvg {\n  display: block;\n}\n\n.action {\n  display: none;\n  margin: 0.5rem;\n  cursor: pointer;\n  text-decoration: underline;\n  user-select: none;\n}\n\n.action.active {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
