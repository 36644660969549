// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dragger-row {
    display: flex;
    height: 100%;
}

.middle {
    border: 1px solid lightgray;
    width: 20px;
    min-height: 20px;
    height: 100%;
}

.final-padding {
    width: 10px;
}

.dropdown-item {
    cursor: pointer;
}

.dropdown, .dropdown-toggle {
    width: 100% !important;
    height: 100% !important;
}

.dropdown-toggle:after {
    width: 100%;
    height: 100%;
    content: none !important;
}

.dropdown-item {
    cursor: pointer;
}

.dropdown, .dropdown-toggle {
    width: 100% !important;
    height: 100% !important;
}

.dropdown-toggle:after {
    width: 100%;
    height: 100%;
    content: none !important;
}

.middle.depth-0 {
    background-color: hsl(198, 100%, 100%);
}

.middle.depth-1 {
    background-color: hsl(198, 100%, 95%);
}

.middle.depth-2 {
    background-color: hsl(198, 100%, 85%);
}

.middle.depth-3 {
    background-color: hsl(198, 100%, 70%);
}

.middle.depth-4 {
    background-color: hsl(198, 100%, 50%);
}
`, "",{"version":3,"sources":["webpack://./src/app/dragger/dragger.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,2BAA2B;IAC3B,WAAW;IACX,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,wBAAwB;AAC5B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,wBAAwB;AAC5B;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,qCAAqC;AACzC;;AAEA;IACI,qCAAqC;AACzC;;AAEA;IACI,qCAAqC;AACzC;;AAEA;IACI,qCAAqC;AACzC","sourcesContent":[".dragger-row {\n    display: flex;\n    height: 100%;\n}\n\n.middle {\n    border: 1px solid lightgray;\n    width: 20px;\n    min-height: 20px;\n    height: 100%;\n}\n\n.final-padding {\n    width: 10px;\n}\n\n.dropdown-item {\n    cursor: pointer;\n}\n\n.dropdown, .dropdown-toggle {\n    width: 100% !important;\n    height: 100% !important;\n}\n\n.dropdown-toggle:after {\n    width: 100%;\n    height: 100%;\n    content: none !important;\n}\n\n.dropdown-item {\n    cursor: pointer;\n}\n\n.dropdown, .dropdown-toggle {\n    width: 100% !important;\n    height: 100% !important;\n}\n\n.dropdown-toggle:after {\n    width: 100%;\n    height: 100%;\n    content: none !important;\n}\n\n.middle.depth-0 {\n    background-color: hsl(198, 100%, 100%);\n}\n\n.middle.depth-1 {\n    background-color: hsl(198, 100%, 95%);\n}\n\n.middle.depth-2 {\n    background-color: hsl(198, 100%, 85%);\n}\n\n.middle.depth-3 {\n    background-color: hsl(198, 100%, 70%);\n}\n\n.middle.depth-4 {\n    background-color: hsl(198, 100%, 50%);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
