// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-body label {
  font-weight: bold;
  margin-top: 20px;
  display: block;
}
.modal-body textarea {
  width: 100%;
  display: block;
}
.modal-body button {
  margin-top: 20px;
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/paratext-comment/paratext-comment.component.scss"],"names":[],"mappings":"AACC;EACC,iBAAA;EACA,gBAAA;EACA,cAAA;AAAF;AAGC;EACC,WAAA;EACA,cAAA;AADF;AAIC;EACC,gBAAA;EACA,cAAA;AAFF","sourcesContent":[".modal-body {\n\tlabel {\n\t\tfont-weight: bold;\n\t\tmargin-top: 20px;\n\t\tdisplay: block;\n\t}\n\n\ttextarea {\n\t\twidth: 100%;\n\t\tdisplay: block;\n\t}\n\n\tbutton {\n\t\tmargin-top: 20px;\n\t\tdisplay: block;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
