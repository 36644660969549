// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.note-comment {
  border-left: 3px solid #9ad3de;
  margin: 20px 0px;
  padding: 5px;
}
.note-comment label {
  display: block;
  font-weight: bold;
  margin-top: 20px;
}
.note-comment textarea {
  width: 100%;
}
.note-comment .original {
  position: relative;
}
.note-comment .original .hider {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: black;
  opacity: 0.05;
}

button {
  display: block;
  margin: 30px 0;
}

.modal-body {
  overflow: auto;
  background-color: white;
}

.close {
  margin: 5px !important;
}

.modal-header {
  padding: 0.3rem !important;
}`, "",{"version":3,"sources":["webpack://./src/app/comment/comment.component.scss"],"names":[],"mappings":"AAAA;EACC,8BAAA;EACA,gBAAA;EACA,YAAA;AACD;AACC;EACC,cAAA;EACA,iBAAA;EACA,gBAAA;AACF;AAEC;EACC,WAAA;AAAF;AAGC;EACC,kBAAA;AADF;AAGE;EACC,WAAA;EACA,YAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,uBAAA;EACA,aAAA;AADH;;AAMA;EACC,cAAA;EACA,cAAA;AAHD;;AAOA;EACE,cAAA;EACA,uBAAA;AAJF;;AAOA;EACE,sBAAA;AAJF;;AAOA;EACE,0BAAA;AAJF","sourcesContent":[".note-comment {\n\tborder-left: 3px solid #9ad3de;\n\tmargin: 20px 0px;\n\tpadding: 5px;\n\n\tlabel {\n\t\tdisplay: block;\n\t\tfont-weight: bold;\n\t\tmargin-top: 20px;\n\t}\n\n\ttextarea {\n\t\twidth: 100%;\n\t}\n\n\t.original {\n\t\tposition: relative;\n\n\t\t.hider {\n\t\t\twidth: 100%;\n\t\t\theight: 100%;\n\t\t\tposition: absolute;\n\t\t\ttop: 0px;\n\t\t\tleft: 0px;\n\t\t\tbackground-color: black;\n\t\t\topacity: 0.05;\n\t\t}\n\t}\n}\n\nbutton {\n\tdisplay: block;\n\tmargin: 30px 0;\n}\n\n\n.modal-body {\n  overflow: auto;\n  background-color: white;\n}\n\n.close {\n  margin: 5px !important;\n}\n\n.modal-header {\n  padding: 0.3rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
