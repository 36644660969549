// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section {
  display: inline-block;
  vertical-align: top;
}

svg {
  height: 120px;
  width: 100%;
}

.noteText, .syllableText {
  white-space: pre;
  padding-left: 5px;
  text-align: left;
  border: 1px dashed gray;
  margin: 5px;
  font-family: "Times New Roman", Times, serif;
  font-size: 16px;
  line-height: 25px;
  height: 25px;
}

.vhidden {
  visibility: hidden;
}

.dnone {
  display: none;
}

svg .note {
  cursor: pointer;
}

.read-only .noteText {
  display: none;
}
.read-only .syllableText {
  border: none;
}
.read-only svg {
  height: 90px;
}`, "",{"version":3,"sources":["webpack://./src/app/notes/notes.component.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,mBAAA;AACJ;;AAGA;EACI,aAAA;EACA,WAAA;AAAJ;;AAGA;EACI,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,uBAAA;EACA,WAAA;EACA,4CAAA;EACA,eAAA;EACA,iBAAA;EACA,YAAA;AAAJ;;AAGA;EACI,kBAAA;AAAJ;;AAGA;EACI,aAAA;AAAJ;;AAGA;EACI,eAAA;AAAJ;;AAII;EAAY,aAAA;AAAhB;AACI;EAAgB,YAAA;AAEpB;AADI;EAAM,YAAA;AAIV","sourcesContent":[".section {\n    display: inline-block;\n    vertical-align: top;\n}\n\n\nsvg {\n    height: 120px;\n    width: 100%;\n}\n\n.noteText, .syllableText {\n    white-space: pre;\n    padding-left: 5px;\n    text-align: left;\n    border: 1px dashed gray;\n    margin: 5px;\n    font-family: \"Times New Roman\", Times, serif;\n    font-size: 16px;\n    line-height: 25px;\n    height: 25px;\n}\n\n.vhidden {\n    visibility: hidden;\n}\n\n.dnone {\n    display: none;\n}\n\nsvg .note {\n    cursor: pointer;\n}\n\n.read-only {\n    .noteText { display: none; }\n    .syllableText { border: none; }\n    svg { height: 90px; }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
