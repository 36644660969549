// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  margin: 1rem;
}

.info-box {
  max-width: 40em;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}
.info-box.open {
  border: 1px solid #9ad3de;
}
.info-box.open .info-box-content {
  display: block;
}

.info-box-content {
  display: none;
}

.toggle {
  margin-bottom: 2rem;
}

.part {
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 1px 0 #eee;
  margin: 2rem 0 1rem 0;
}`, "",{"version":3,"sources":["webpack://./src/app/comment/comment-info/comment-info.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,eAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;AACF;AACE;EACE,yBAAA;AACJ;AACI;EACE,cAAA;AACN;;AAIA;EACE,aAAA;AADF;;AAIA;EACE,mBAAA;AADF;;AAIA;EACE,iBAAA;EACA,6BAAA;EACA,wBAAA;EACA,qBAAA;AADF","sourcesContent":[":host {\n  margin: 1rem;\n}\n\n.info-box {\n  max-width: 40em;\n  margin: auto;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 1rem;\n\n  &.open {\n    border: 1px solid #9ad3de;\n\n    .info-box-content {\n      display: block;\n    }\n  }\n}\n\n.info-box-content {\n  display: none;\n}\n\n.toggle {\n  margin-bottom: 2rem;\n}\n\n.part {\n  font-weight: bold;\n  border-bottom: 1px solid #ccc;\n  box-shadow: 0 1px 0 #eee;\n  margin: 2rem 0 1rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
