// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.062745098);
  border: 1px solid rgba(0, 0, 0, 0.1254901961);
  display: grid;
  grid-gap: 1rem;
}

.add-row {
  grid-column: 1/-2;
  grid-row: -2/span 1;
  text-align: center;
  background-color: #89bdd3;
  cursor: pointer;
  height: 1.5rem;
}

.add-col {
  grid-column: -2/span 1;
  grid-row: 1/-2;
  display: grid;
  align-content: center;
  text-align: center;
  background-color: #89bdd3;
  cursor: pointer;
  width: 1.5rem;
}

.grid-entry {
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  grid-template-rows: auto minmax(0, 1fr);
}

.del-row, .del-col {
  background-color: #89bdd3;
  width: 0;
  height: 0;
  overflow: hidden;
  display: grid;
  place-items: center;
  cursor: pointer;
  visibility: hidden;
  -webkit-user-select: none;
          user-select: none;
}

.first-row .del-col, .first-col .del-row {
  visibility: visible;
  min-width: 1.5rem;
  min-height: 1.5rem;
  width: unset;
  height: unset;
}`, "",{"version":3,"sources":["webpack://./src/app/complex-comment/comment-tree/comment-tree-grid/comment-tree-grid.component.scss","webpack://./src/app/complex-comment/comment-tree/_common.scss"],"names":[],"mappings":"AAEA;ECDE,cAAA;EACA,aAAA;EACA,4CAAA;EACA,6CAAA;EDCA,aAAA;EACA,cAAA;AACF;;AAEA;EACE,iBAAA;EACA,mBAAA;EACA,kBAAA;EACA,yBAAA;EACA,eAAA;EACA,cAAA;AACF;;AAEA;EACE,sBAAA;EACA,cAAA;EACA,aAAA;EACA,qBAAA;EACA,kBAAA;EACA,yBAAA;EACA,eAAA;EACA,aAAA;AACF;;AAEA;EACE,aAAA;EACA,0CAAA;EACA,uCAAA;AACF;;AAEA;EACE,yBAAA;EACA,QAAA;EACA,SAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,yBAAA;UAAA,iBAAA;AACF;;AAEA;EACE,mBAAA;EACA,iBAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;AACF","sourcesContent":["@use \"../common\";\n\n:host {\n  @include common.block;\n\n  display: grid;\n  grid-gap: 1rem;\n}\n\n.add-row {\n  grid-column: 1 / -2;\n  grid-row: -2 / span 1;\n  text-align: center;\n  background-color: #89bdd3;\n  cursor: pointer;\n  height: 1.5rem;\n}\n\n.add-col {\n  grid-column: -2 / span 1;\n  grid-row: 1 / -2;\n  display: grid;\n  align-content: center;\n  text-align: center;\n  background-color: #89bdd3;\n  cursor: pointer;\n  width: 1.5rem;\n}\n\n.grid-entry {\n  display: grid;\n  grid-template-columns: auto minmax(0, 1fr);\n  grid-template-rows: auto minmax(0, 1fr);\n}\n\n.del-row, .del-col {\n  background-color: #89bdd3;\n  width: 0;\n  height: 0;\n  overflow: hidden;\n  display: grid;\n  place-items: center;\n  cursor: pointer;\n  visibility: hidden;\n  user-select: none;\n}\n\n.first-row .del-col, .first-col .del-row {\n  visibility: visible;\n  min-width: 1.5rem;\n  min-height: 1.5rem;\n  width: unset;\n  height: unset;\n}\n","@mixin block {\n  display: block;\n  padding: 1rem;\n  background-color: #00000010;\n  border: 1px solid #00000020;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
