// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr 3fr;
    grid-gap: 20px;
    align-items: center;
}

.table span {
    text-align: right;
}
`, "",{"version":3,"sources":["webpack://./src/app/source-query/source-query.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sCAAsC;IACtC,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".table {\n    display: grid;\n    grid-template-columns: 1fr 3fr 1fr 3fr;\n    grid-gap: 20px;\n    align-items: center;\n}\n\n.table span {\n    text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
