// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.062745098);
  border: 1px solid rgba(0, 0, 0, 0.1254901961);
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
}`, "",{"version":3,"sources":["webpack://./src/app/complex-comment/comment-tree/comment-tree-leaf/comment-tree-leaf.component.scss","webpack://./src/app/complex-comment/comment-tree/_common.scss"],"names":[],"mappings":"AACA;ECAE,cAAA;EACA,aAAA;EACA,4CAAA;EACA,6CAAA;EDAA,aAAA;EACA,qCAAA;EACA,kCAAA;AAEF","sourcesContent":["@use \"../common\";\n:host {\n  @include common.block;\n\n  display: grid;\n  grid-template-columns: minmax(0, 1fr);\n  grid-template-rows: minmax(0, 1fr);\n}\n","@mixin block {\n  display: block;\n  padding: 1rem;\n  background-color: #00000010;\n  border: 1px solid #00000020;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
