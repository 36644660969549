import { Component } from '@angular/core';

@Component({
  selector: 'app-comment-tree-bracket',
  templateUrl: './comment-tree-bracket.component.html',
  styleUrls: ['./comment-tree-bracket.component.scss']
})
export class CommentTreeBracketComponent {

}
